<!--
 * @Author: Ten
 * @Date: 2021/08/13 12:06
 * @LastEditTime: 2021/09/29 17:45
 * @LastEditors: Ten
 * @Description: 企业微信扫码登录
-->
<template>
  <div :class="$style.wrapper">
    <div id="wwLogin" :class="$style.panel"></div>
  </div>
</template>

<script>
export default {
  name: 'WWLogin',
  mounted() {
    this.init()
  },
  methods: {
    init() { // 初始化企业微信二维码
      window.WwLogin({
        id: 'wwLogin',
        appid: 'ww4903e5dc698f0885',
        agentid: '1000047',
        // redirect_uri: encodeURIComponent(window.location.href.replace(window.location.hash, '') + '#/'),
        redirect_uri: window.G_redirect_uri,
        // redirect_uri: 'http://res.indra.vip:88',
        // state: ''
        state: Date.parse(new Date())
      })
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  justify-content: center;
}
.panel {
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: $color--white;
  border-radius: $border-radius--base;
}
</style>
