<!--
 * @Author: Ten
 * @Date: 2021/08/13 12:12
 * @LastEditTime: 2022/03/01 15:51
 * @LastEditors: Ten
 * @Description: 账号密码登录
-->
<template>
  <div :class="$style.wrapper">
    <div :class="$style.title">账号密码登录</div>
    <el-form
      ref="loginForm"
      :model="loginForm"
      autocomplete="on"
      label-position="left"
    >
      <el-form-item prop="username">
        <el-input
          ref="username"
          v-model.trim="loginForm.username"
          placeholder="请输入账号"
          name="username"
          type="text"
          size="large"
          tabindex="1"
          autocomplete="on"
        >
          <template #prefix>
            <svg-icon icon-class="login-user" :class-name="$style.icon" />
          </template>
        </el-input>
      </el-form-item>

      <el-form-item prop="password">
        <el-input
          :key="passwordInputType"
          ref="password"
          v-model.trim="loginForm.password"
          :type="passwordInputType"
          size="large"
          placeholder="请输入密码"
          name="password"
          tabindex="2"
          autocomplete="on"
        >
          <template #prefix>
            <svg-icon icon-class="login-pwd" :class-name="$style.icon" />
          </template>
          <template #suffix>
            <span :class="$style['show-pwd']" @click="showPwd">
              <svg-icon :icon-class="passwordInputType === 'password' ? 'login-eye--close' : 'login-eye'" />
            </span>
          </template>
        </el-input>
      </el-form-item>
      <div :class="$style.forget">忘记密码请联系管理员</div>

      <div :class="$style.captcha">
        <el-form-item
          prop="code"
          :class="$style['captcha-item']"
        >
          <el-input
            ref="code"
            v-model.trim="loginForm.code"
            type="text"
            size="large"
            placeholder="验证码"
            name="code"
            tabindex="3"
            maxlength="4"
            autocomplete="off"
            @keyup.enter.native="handleLogin"
          >
            <template #prefix>
              <svg-icon icon-class="login-code" :class-name="$style.icon" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          :class="$style['captcha-item']"
        >
          <Captcha
            ref="captcha"
            class="login__captcha"
            width="130px"
            height="40px"
            radius="6px"
            @change="onCaptchaChange"
          />
        </el-form-item>
      </div>

      <el-button
        :loading="loading"
        type="primary"
        size="large"
        :class="$style.submit"
        :disabled="!enableLoginButton"
        @click.native.prevent="handleLogin"
      >
        {{ loading ? '登录中' : '登 录' }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { validCaptcha } from '@/utils/validate'
import { Captcha } from '@/components/captcha'

export default {
  name: 'PasswordLogin',
  components: {
    Captcha
  },
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        code_key: '',
        code: ''
      },
      passwordInputType: 'password',
      loading: false,
      redirect: undefined, // 重定向地址
      otherQuery: {}
    }
  },
  computed: {
    enableLoginButton() { // 登录按钮是否可以点击
      return !!(this.loginForm.username && this.loginForm.password && this.loginForm.code)
    }
  },
  mounted() {
    this.getCaptcha()
  },
  created() {
    const query = this.$route.query
    if (query) {
      this.redirect = query.redirect
      this.otherQuery = this.getOtherQuery(query)
    }
  },
  methods: {
    showPwd() { // 隐藏/显示密码
      if (this.passwordInputType === 'password') {
        this.passwordInputType = 'text'
      } else {
        this.passwordInputType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    getCaptcha() { // 获取验证码
      this.$refs.captcha.init()
    },
    onCaptchaChange(res) { // 验证码改变事件
      this.loginForm.code_key = res.key
    },
    handleLogin() { // 登录按钮点击事件
      if (this.loading) {
        return
      }
      if (this.loginForm.username === '') {
        this.$message.error('请输入账号')
        return
      }
      if (this.loginForm.password === '') {
        this.$message.error('请输入密码')
        return
      }
      if (this.loginForm.code === '') {
        this.$message.error('请输入验证码')
        return
      }
      if (!validCaptcha(this.loginForm.code)) {
        this.$message.error('验证码格式不正确，请重新输入')
        return
      }
      this.loading = true
      this.$store.dispatch('user/login', this.loginForm).then(() => {
        this.$router.push({
          path: this.redirect || '/',
          query: this.otherQuery
        }, () => {
          this.loading = false
        }, (err) => {
          this.loading = false
          console.info(err.message)
        })
      }).catch(err => {
        console.log(err)
        this.loginForm.code = ''
        this.getCaptcha()
        this.loading = false
      })
    },
    getOtherQuery(query) { // 解析链接参数
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 60px;
  max-width: $login-panel-inner-width;
  width: percentage(math.div($login-panel-inner-width, $login-panel-width));
  :global {
    .el-form-item__content {
      font-size: $font-size--medium;
    }
    .el-input__suffix {
      right: 0;
      display: flex;
      align-items: center;
    }
    .el-input__prefix {
      left: 0;
      display: flex;
      align-items: center;
    }
    .el-input--suffix {
      .el-input__inner {
        padding-right: 38px;
      }
    }
    .el-input--prefix {
      .el-input__inner {
        padding-left: 38px;
      }
    }
  }
}
.title {
  margin-bottom: 40px;
  text-align: center;
  font-size: $font-size--medium;
  font-weight: bold;
  color: $color-text--primary;
}
.icon {
  display: inline-block;
  margin-left: 13px;
  margin-right: 13px;
  width: 16px;
  height: 16px;
  color: $color--primary;
}
.show-pwd {
  display: flex;
  align-items: center;
  margin-left: 13px;
  margin-right: 13px;
  height: 100%;
  font-size: $font-size--medium;
  color: #B8BECB;
  user-select: none;
  &:hover {
    color: $color--primary;
    cursor: pointer;
  }
}
.captcha {
  display: flex;
  align-items: center;
  .captcha-item {
    margin-right: 20px;
    margin-bottom: 0 !important;
    &:last-child {
      margin-right: 0;
      border-width: 0;
    }
  }
}

.forget {
  margin-top: -10px;
  margin-bottom: 25px;
  text-align: right;
  font-size: $font-size--base;
  color: $color-text--secondary;
}
.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  height: 40px;
  font-size: $font-size--medium;
}
</style>
