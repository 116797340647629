<!--
 * @Author: Ten
 * @Date: 2021/08/09 11:16
 * @LastEditTime: 2021/10/14 14:39
 * @LastEditors: Ten
 * @Description: 登录页面
-->
<template>
  <div :class="$style.wrapper">
    <div :class="$style.inner">
      <div :class="$style.header">
        <div :class="$style.title">{{ appName }}</div>
      </div>
      <div :class="$style.panel">
        <keep-alive>
          <WWLogin
            v-if="loginType === 'ww'"
          />
        </keep-alive>
        <keep-alive>
          <PasswordLogin
            v-if="loginType === 'pwd'"
          />
        </keep-alive>
        <div
          :class="$style.switch"
          @click="handleSwitchLoginType"
        >
          {{ loginTypeEnum[loginType] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { appName } from '@/settings'
import WWLogin from './comps/WWLogin.vue'
import PasswordLogin from './comps/PasswordLogin.vue'

export default {
  name: 'Login',
  components: {
    WWLogin,
    PasswordLogin
  },
  data() {
    return {
      appName,
      loginTypeEnum: {
        ww: '账号密码登录',
        password: '企业微信登录'
      },
      loginType: 'pwd'
    }
  },
  created() {
    this.$store.dispatch('user/removeToken')
  },
  methods: {
    handleSwitchLoginType() {
      this.loginType = this.loginType === 'ww' ? 'pwd' : 'ww'
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100%;
  background-color: #F5F7FA;
}
.inner {
  margin: auto;
  max-width: $login-panel-width;
  width: 95%;
}
.header {
  position: relative;
  margin-bottom: 50px;
}
.title {
  text-align: center;
  font-size: 30px;
  color: $color-text--primary;
}
.panel {
  position: relative;
  width: 100%;
  height: 520px;
  border-radius: 16px;
  box-shadow: 0 0 10px 0 rgba($color--primary, 0.1);
  background-color: $color--white;
  overflow: hidden;
}
.switch {
  margin-bottom: 30px;
  text-align: center;
  user-select: none;
  color: $color--primary;
  &:hover {
    cursor: pointer;
  }
}
</style>
